// 
// _dropdown.scss
// 

//Dropdowns
@each $name,
$value in $theme-colors {
    .dropdown-#{$name} {
        .dropdown-menu {
            .dropdown-item {
                &:hover,
                &.active,
                &:active,
                &.focus,
                &:focus {
                    background-color: transparent;
                    color: #{$value} !important;
                }
            }
        }
    }
}

.btn-group {
    .dropdown-toggle {
        &:after {
            content: "";
            position: relative;
            right: -4px;
            top: -2px;
            border: solid $white;
            border-radius: 0.5px;
            border-width: 0 2px 2px 0;
            padding: 3px;
            transform: rotate(45deg);
            margin-left: 0;
            vertical-align: 0;
        }
    }
    .dropdown-menu {
        margin-top: 10px;
        border: 0;
        border-radius: 5px;
        box-shadow: $shadow;
    }
}

.dropdown {
    .dropdown-toggle {
        &:after {
            display: none;
        }
    }
}